import React from 'react';

const ProfileAdmin = () => {
    return (
        <div>
            profile
        </div>
    );
}

export default ProfileAdmin;
