import React from 'react';

const UpdateFacilities = () => {
    return (
        <div>
            
        </div>
    );
}

export default UpdateFacilities;
