import React from 'react';

const AboutUs = () => {
    return (
        <div>
            about us
        </div>
    );
}

export default AboutUs;
